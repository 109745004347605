/** @jsxImportSource @emotion/react */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { postSuccessionOnboarding } from '../api/successionOnboarding';
import { Tutorial } from "../components/common/Tutorial";
import { ContainerWithSidebar } from "../components/display/ContainerWithSidebar";
import { Stepper } from "../components/stepper/Stepper";
import { StepperMenu } from "../components/stepper/StepperMenu";
import { Button } from '../components/ui/Button';
import { CardBox } from "../components/ui/CardBox";
import { Container } from "../components/ui/Container";
import { ScrollDisplay } from "../components/ui/ScrollDisplay";
import { TransParagraphs } from '../components/ui/Trans';
import { Typo } from "../components/ui/Typo";
import { useFormUpdaters, useFormValues } from "../hooks/form";
import { useMediaQuery } from '../hooks/mediaQuery';
import { setYupLocale } from "../lib/setYupLocale";
import { addCustomDateValidator, addCustomEmailValidator } from '../lib/validators';
import { mq, palette, spacing } from "../theme";

setYupLocale(yup);
addCustomEmailValidator(yup);
addCustomDateValidator(yup);

const dataSchema = yup.object().shape({
	relationship: yup.string(),
	civil_status: yup.string(),
	has_marriage_contract: yup.string(),
	death_date: yup.string().customDate({ max: new Date(), }),
	death_cause: yup.string(),
	address_region: yup.string(),
	street_name: yup.string(),
	street_number: yup.string(),
	street_box: yup.string(),
	zip_code: yup.string(),
	city: yup.string(),
	has_testament: yup.string(),
	children_number: yup.number().min(0),
	has_heir_minor_or_incapable: yup.string(),
	heirs_minor_or_incapable_number: yup.number().min(0),
	has_heir_abroad: yup.string(),
	has_property: yup.string(),
	has_selled_property: yup.string(),
	selled_properties_number: yup.number(),
	has_succession: yup.string(),
	successions_number: yup.number(),
	has_vehicle: yup.string(),
	vehicles_number: yup.number(),
	has_asset_abroad: yup.string(),
	referral: yup.string(),
	undertaker: yup.string(),
	other: yup.string(),
	full_name: yup.string(), // Hidden, fallback if no token
  mutual_insurance: yup.string(),
  has_insurance_broker: yup.string(),
  insurance_broker_name: yup.string(),
  insurance_broker_email: yup.string(),
  health_insurance: yup.string(),
  health_insurance_number: yup.string(),
  damage_insurance: yup.string(),
  damage_insurance_number: yup.string(),
  car_insurance: yup.string(),
  car_insurance_number: yup.string(),
  fire_insurance: yup.string(),
  fire_insurance_number: yup.string(),
  has_other_insurance: yup.string(),
  other_insurance_comment: yup.string(),
  water_supplier: yup.string(),
  water_supplier_client_number: yup.string(),
  water_supplier_meter_reading: yup.string(),
  water_supplier_reading_date: yup.string().customDate({ max: new Date(), }),
  water_supplier_meter_number: yup.string(),
  gas_supplier: yup.string(),
  gas_supplier_client_number: yup.string(),
  gas_supplier_meter_reading: yup.string(),
  gas_supplier_reading_date: yup.string().customDate({ max: new Date(), }),
  gas_supplier_meter_number: yup.string(),
  electricity_supplier: yup.string(),
  electricity_supplier_client_number: yup.string(),
  electricity_supplier_meter_reading: yup.string(),
  electricity_supplier_reading_date: yup.string().customDate({ max: new Date(), }),
  electricity_supplier_meter_number: yup.string(),
  numerics_supplier: yup.string(),
  numerics_supplier_client_number: yup.string(),
  other_contracts_comment: yup.string(),
  other_comment: yup.string(),
  numerics_supplier_landline_number: yup.string(),
  numerics_supplier_mobile_number: yup.string(),
});

const defaultData = {
	relationship: '',
	civil_status: '',
	has_marriage_contract: '',
	death_date: '',
	death_cause: '',
	address_region: '',
	street_name: '',
	street_number: '',
	street_box: '',
	zip_code: '',
	city: '',
	has_testament: '',
	children_number: 0,
	has_heir_minor_or_incapable: '',
	heirs_minor_or_incapable_number: 0,
	has_heir_abroad: '',
	has_property: '',
	has_selled_property: '',
	selled_properties_number: 0,
	has_succession: '',
	successions_number: 0,
	has_vehicle: '',
	vehicles_number: 0,
	has_asset_abroad: '',
	referral: '',
	undertaker: '',
	other: '',
	full_name: '', // Hidden, fallback if no token
  mutual_insurance: '',
  has_insurance_broker: '',
  insurance_broker_name: '',
  insurance_broker_email: '',
  health_insurance: '',
  health_insurance_number: '',
  damage_insurance: '',
  damage_insurance_number: '',
  car_insurance: '',
  car_insurance_number: '',
  fire_insurance: '',
  fire_insurance_number: '',
  has_other_insurance: '',
  other_insurance_comment: '',
  water_supplier: '',
  water_supplier_client_number: '',
  water_supplier_meter_reading: '',
  water_supplier_reading_date: '',
  water_supplier_meter_number: '',
  gas_supplier: '',
  gas_supplier_client_number: '',
  gas_supplier_meter_reading: '',
  gas_supplier_reading_date: '',
  gas_supplier_meter_number: '',
  electricity_supplier: '',
  electricity_supplier_client_number: '',
  electricity_supplier_meter_reading: '',
  electricity_supplier_reading_date: '',
  electricity_supplier_meter_number: '',
  numerics_supplier: '',
  numerics_supplier_client_number: '',
  other_contracts_comment: '',
  other_comment: '',
  numerics_supplier_landline_number: '',
  numerics_supplier_mobile_number: ''
};

const steps = [
	{
		route: 'intro',
		label: ({ t }) => t('successionOnboarding.steps.intro.label'),
		title: ({ t }) => t('successionOnboarding.steps.intro.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'successionOnboarding.steps.intro.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		route: 'part_one',
		label: ({ t }) => t('successionOnboarding.steps.partOne.label'),
		title: ({ t }) => t('successionOnboarding.steps.partOne.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'successionOnboarding.steps.partOne.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		depth: 1,
		route: 'relationship',
		label: ({ t }) => t('successionOnboarding.steps.relationship.label'),
		title: ({ t }) => t('successionOnboarding.steps.relationship.title'),
		items: [
			{
				type: 'field',
				name: 'relationship',
				props: {
					type: 'select',
					label: 'successionOnboarding.steps.relationship.form.label',
					options: [
						{ value: 'mom', label: 'successionOnboarding.steps.relationship.form.mom' },
						{ value: 'dad', label: 'successionOnboarding.steps.relationship.form.dad' },
						{ value: 'wife', label: 'successionOnboarding.steps.relationship.form.wife' },
						{ value: 'husband', label: 'successionOnboarding.steps.relationship.form.husband' },
						{ value: 'grandfather', label: 'successionOnboarding.steps.relationship.form.grandfather' },
						{ value: 'grandmother', label: 'successionOnboarding.steps.relationship.form.grandmother' },
						{ value: 'sister', label: 'successionOnboarding.steps.relationship.form.sister' },
						{ value: 'brother', label: 'successionOnboarding.steps.relationship.form.brother' },
						{ value: 'daughter', label: 'successionOnboarding.steps.relationship.form.daughter' },
						{ value: 'son', label: 'successionOnboarding.steps.relationship.form.son' },
						{ value: 'aunt', label: 'successionOnboarding.steps.relationship.form.aunt' },
						{ value: 'uncle', label: 'successionOnboarding.steps.relationship.form.uncle' },
						{ value: 'stepmother', label: 'successionOnboarding.steps.relationship.form.stepmother' },
						{ value: 'stepdad', label: 'successionOnboarding.steps.relationship.form.stepdad' },
						{ value: 'cousin_f', label: 'successionOnboarding.steps.relationship.form.cousinF' },
						{ value: 'cousin_m', label: 'successionOnboarding.steps.relationship.form.cousinM' },
						{ value: 'relative', label: 'successionOnboarding.steps.relationship.form.relative' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.relationship === 'mom' ? t('successionOnboarding.steps.relationship.form.mom')
			: data.relationship === 'dad' ? t('successionOnboarding.steps.relationship.form.dad')
				: data.relationship === 'wife' ? t('successionOnboarding.steps.relationship.form.wife')
					: data.relationship === 'husband' ? t('successionOnboarding.steps.relationship.form.husband')
						: data.relationship === 'grandfather' ? t('successionOnboarding.steps.relationship.form.grandfather')
							: data.relationship === 'grandmother' ? t('successionOnboarding.steps.relationship.form.grandmother')
								: data.relationship === 'sister' ? t('successionOnboarding.steps.relationship.form.sister')
									: data.relationship === 'brother' ? t('successionOnboarding.steps.relationship.form.brother')
										: data.relationship === 'daughter' ? t('successionOnboarding.steps.relationship.form.daughter')
											: data.relationship === 'son' ? t('successionOnboarding.steps.relationship.form.son')
												: data.relationship === 'aunt' ? t('successionOnboarding.steps.relationship.form.aunt')
													: data.relationship === 'uncle' ? t('successionOnboarding.steps.relationship.form.uncle')
														: data.relationship === 'stepmother' ? t('successionOnboarding.steps.relationship.form.stepmother')
															: data.relationship === 'stepdad' ? t('successionOnboarding.steps.relationship.form.stepdad')
																: data.relationship === 'cousin_f' ? t('successionOnboarding.steps.relationship.form.cousinF')
																	: data.relationship === 'cousin_m' ? t('successionOnboarding.steps.relationship.form.cousinM')
																		: data.relationship === 'relative' ? t('successionOnboarding.steps.relationship.form.relative')
																			: '',
	},
	{
		depth: 1,
		route: 'civil_status',
		label: ({ t }) => t('successionOnboarding.steps.civilStatus.label'),
		title: ({ t }) => t('successionOnboarding.steps.civilStatus.title'),
		items: [
			{
				type: 'oneOf',
				name: 'civil_status',
				props: {
					options: [
						{ value: "married", label: "successionOnboarding.steps.civilStatus.form.married" },
						{ value: "widowed", label: "successionOnboarding.steps.civilStatus.form.widowed" },
						{ value: "divorced", label: "successionOnboarding.steps.civilStatus.form.divorced" },
						{ value: "single", label: "successionOnboarding.steps.civilStatus.form.single" },
						{ value: "legal_cohabitant", label: "successionOnboarding.steps.civilStatus.form.legal_cohabitant" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.civil_status === 'married' ? t('successionOnboarding.steps.civilStatus.form.married')
			: data.civil_status === 'widowed' ? t('successionOnboarding.steps.civilStatus.form.widowed')
				: data.civil_status === 'divorced' ? t('successionOnboarding.steps.civilStatus.form.divorced')
					: data.civil_status === 'single' ? t('successionOnboarding.steps.civilStatus.form.single')
						: data.civil_status === 'legal_cohabitant' ? t('successionOnboarding.steps.civilStatus.form.legal_cohabitant')
							: data.civil_status === 'unknown' ? t('form.unknown')
								: '',
		condition: (data) => data.relationship !== 'wife' && data.relationship !== 'husband',
	},
	{
		depth: 2,
		route: 'has_marriage_contract',
		label: ({ t }) => t('successionOnboarding.steps.hasMarriageContract.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasMarriageContract.title'),
		items: [
			{
				type: 'oneOf',
				name: 'has_marriage_contract',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_marriage_contract === 'yes' ? t('global.yes')
			: data.has_marriage_contract === 'no' ? t('global.no')
				: data.has_marriage_contract === 'unknown' ? t('form.unknown')
					: '',
		condition: (data) => (data.relationship === 'wife' || data.relationship === 'husband') || data.civil_status === 'married',
	},
	{
		depth: 1,
		route: 'death_date',
		label: ({ t }) => t('successionOnboarding.steps.deathDate.label'),
		title: ({ t }) => t('successionOnboarding.steps.deathDate.title'),
		subtitle: ({ t }) => t('successionOnboarding.steps.deathDate.subtitle'),
		items: [
			{
				type: 'field',
				name: 'death_date',
				props: {
					type: 'multipart_date',
					label: 'successionOnboarding.steps.deathDate.form.label',
				},
			},
		],
		preview: ({ data, t }) => data.death_date,
	},
	{
		depth: 1,
		route: 'death_cause',
		label: ({ t }) => t('successionOnboarding.steps.deathCause.label'),
		title: ({ t }) => t('successionOnboarding.steps.deathCause.title'),
		items: [
			{
				type: 'oneOf',
				name: 'death_cause',
				props: {
					style: 'row',
					options: [
						{ value: "natural", label: "successionOnboarding.steps.deathCause.form.natural", description: "successionOnboarding.steps.deathCause.form.naturalDescription" },
						{ value: "accidental", label: "successionOnboarding.steps.deathCause.form.accidental" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.death_cause === 'natural' ? t('successionOnboarding.steps.deathCause.form.natural')
			: data.death_cause === 'accidental' ? t('successionOnboarding.steps.deathCause.form.accidental')
				: data.death_cause === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		depth: 1,
		route: 'address_region',
		label: ({ t }) => t('successionOnboarding.steps.addressRegion.label'),
		title: ({ t }) => t('successionOnboarding.steps.addressRegion.title'),
		subtitle: ({ t }) => t('successionOnboarding.steps.addressRegion.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'address_region',
				props: {
					options: [
						{ value: "changed_recently", label: "successionOnboarding.steps.addressRegion.form.changedRecently", description: "successionOnboarding.steps.addressRegion.form.changedRecentlyDescription" },
						{ value: "brussels", label: "successionOnboarding.steps.addressRegion.form.brussels" },
						{ value: "wallonia", label: "successionOnboarding.steps.addressRegion.form.wallonia" },
						{ value: "flanders", label: "successionOnboarding.steps.addressRegion.form.flanders" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.address_region === 'brussels' ? t('successionOnboarding.steps.addressRegion.form.brussels')
			: data.address_region === 'wallonia' ? t('successionOnboarding.steps.addressRegion.form.wallonia')
				: data.address_region === 'flanders' ? t('successionOnboarding.steps.addressRegion.form.flanders')
					: data.address_region === 'changed_recently' ? t('successionOnboarding.steps.addressRegion.form.changedRecently')
						: '',
	},
	{
		depth: 1,
		route: 'address',
		label: ({ t }) => t('successionOnboarding.steps.address.label'),
		title: ({ t }) => t('successionOnboarding.steps.address.title'),
		items: [
			{
				type: 'field',
				name: 'street_name',
				gridSizes: {
					lg: 6,
				},
				props: {
					type: 'text',
					label: 'form.address.street',
				},
			},
			{
				type: 'field',
				name: 'street_number',
				props: {
					type: 'text',
					label: 'form.address.number',
				},
				gridSizes: {
					sm: 6,
					lg: 3,
				},
			},
			{
				type: 'field',
				name: 'street_box',
				props: {
					type: 'text',
					label: 'form.address.box',
				},
				gridSizes: {
					sm: 6,
					lg: 3,
				},
			},
			{
				type: 'field',
				name: 'zip_code',
				props: {
					type: 'text',
					label: 'form.address.postalCode',
				},
				gridSizes: {
					md: 6,
				},
			},
			{
				type: 'field',
				name: 'city',
				props: {
					type: 'text',
					label: 'form.address.town',
				},
				gridSizes: {
					md: 6,
				},
			},
		],
		preview: ({ data, t }) => ((data.street_name || data.street_number || data.street_box) && (data.zip_code || data.city)) ? `${data.street_name} ${data.street_number}${data.street_box ? ` / bte ${data.street_box}` : ''}, ${data.zip_code} ${data.city}` : '',
	},
	{
		depth: 1,
		route: 'has_testament',
		label: ({ t }) => t('successionOnboarding.steps.hasTestament.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasTestament.title'),
		items: [
			{
				type: 'oneOf',
				name: 'has_testament',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_testament === 'yes' ? t('global.yes')
			: data.has_testament === 'no' ? t('global.no')
				: data.has_testament === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		route: 'part_two',
		label: ({ t }) => t('successionOnboarding.steps.partTwo.label'),
		title: ({ t }) => t('successionOnboarding.steps.partTwo.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'successionOnboarding.steps.partTwo.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		depth: 1,
		route: 'children_number',
		label: ({ t }) => t('successionOnboarding.steps.childrenNumber.label'),
		title: ({ t }) => t('successionOnboarding.steps.childrenNumber.title'),
		items: [
			{
				type: 'field',
				name: 'children_number',
				props: {
					type: 'number',
					label: 'form.number',
				},
			},
		],
		preview: ({ data, t }) => t('successionOnboarding.steps.childrenNumber.preview', { count: data.children_number || 0 }),
	},
	{
		depth: 1,
		route: 'has_heir_minor_or_incapable',
		label: ({ t }) => t('successionOnboarding.steps.hasHeirMinorOrIncapable.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasHeirMinorOrIncapable.title'),
		items: [
			{
				type: 'oneOf',
				name: 'has_heir_minor_or_incapable',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_heir_minor_or_incapable === 'yes' ? t('global.yes')
			: data.has_heir_minor_or_incapable === 'no' ? t('global.no')
				: data.has_heir_minor_or_incapable === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		depth: 1,
		route: 'heirs_minor_or_incapable_number',
		label: ({ t }) => t('successionOnboarding.steps.heirsMinorOrIncapableNumber.label'),
		title: ({ t }) => t('successionOnboarding.steps.heirsMinorOrIncapableNumber.title'),
		items: [
			{
				type: 'field',
				name: 'heirs_minor_or_incapable_number',
				props: {
					type: 'number',
					label: 'form.number',
				},
			},
		],
		preview: ({ data, t }) => data.heirs_minor_or_incapable_number,
		condition: (data) => data.has_heir_minor_or_incapable === 'yes',
	},
	{
		depth: 1,
		route: 'has_heir_abroad',
		label: ({ t }) => t('successionOnboarding.steps.hasHeirAbroad.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasHeirAbroad.title'),
		items: [
			{
				type: 'oneOf',
				name: 'has_heir_abroad',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_heir_abroad === 'yes' ? t('global.yes')
			: data.has_heir_abroad === 'no' ? t('global.no')
				: data.has_heir_abroad === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		route: 'part_tree',
		label: ({ t }) => t('successionOnboarding.steps.partTree.label'),
		title: ({ t }) => t('successionOnboarding.steps.partTree.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'successionOnboarding.steps.partTree.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		depth: 1,
		route: 'has_property',
		label: ({ t }) => t('successionOnboarding.steps.hasProperty.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasProperty.title'),
		subtitle: ({ t }) => t('successionOnboarding.steps.hasProperty.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'has_property',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_property === 'yes' ? t('global.yes')
			: data.has_property === 'no' ? t('global.no')
				: data.has_property === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		depth: 1,
		route: 'has_selled_property',
		label: ({ t }) => t('successionOnboarding.steps.hasSelledProperty.label'),
		title: ({ t, data }) => t('successionOnboarding.steps.hasSelledProperty.title', { number: (data.address_region === 'wallonia' || data.address_region === 'unknown') ? 5 : 3 }),
		subtitle: ({ t }) => t('successionOnboarding.steps.hasSelledProperty.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'has_selled_property',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_selled_property === 'yes' ? t('global.yes')
			: data.has_selled_property === 'no' ? t('global.no')
				: data.has_selled_property === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		depth: 1,
		route: 'selled_properties_number',
		label: ({ t }) => t('successionOnboarding.steps.selledPropertiesNumber.label'),
		title: ({ t, data }) => t('successionOnboarding.steps.selledPropertiesNumber.title', { number: (data.address_region === 'wallonia' || data.address_region === 'unknown') ? 5 : 3 }),
		items: [
			{
				type: 'field',
				name: 'selled_properties_number',
				props: {
					type: 'number',
					label: 'form.number',
				},
			},
		],
		preview: ({ data, t }) => data.selled_properties_number,
		condition: (data) => data.has_selled_property === 'yes',
	},
	{
		depth: 1,
		route: 'has_succession',
		label: ({ t }) => t('successionOnboarding.steps.hasSuccession.label'),
		title: ({ t, data }) => t('successionOnboarding.steps.hasSuccession.title', { number: (data.address_region === 'wallonia' || data.address_region === 'unknown') ? 5 : 3 }),
		items: [
			{
				type: 'oneOf',
				name: 'has_succession',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_succession === 'yes' ? t('global.yes')
			: data.has_succession === 'no' ? t('global.no')
				: data.has_succession === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		depth: 1,
		route: 'successions_number',
		label: ({ t }) => t('successionOnboarding.steps.successionsNumber.label'),
		title: ({ t, data }) => t('successionOnboarding.steps.successionsNumber.title', { number: (data.address_region === 'wallonia' || data.address_region === 'unknown') ? 5 : 3 }),
		items: [
			{
				type: 'field',
				name: 'successions_number',
				props: {
					type: 'number',
					label: 'form.number',
				},
			},
		],
		preview: ({ data, t }) => data.successions_number,
		condition: (data) => data.has_succession === 'yes',
	},
	{
		depth: 1,
		route: 'has_vehicle',
		label: ({ t }) => t('successionOnboarding.steps.hasVehicle.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasVehicle.title'),
		subtitle: ({ t }) => t('successionOnboarding.steps.hasVehicle.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'has_vehicle',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_vehicle === 'yes' ? t('global.yes')
			: data.has_vehicle === 'no' ? t('global.no')
				: data.has_vehicle === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		depth: 1,
		route: 'vehicles_number',
		label: ({ t }) => t('successionOnboarding.steps.vehiclesNumber.label'),
		title: ({ t }) => t('successionOnboarding.steps.vehiclesNumber.title'),
		items: [
			{
				type: 'field',
				name: 'vehicles_number',
				props: {
					type: 'number',
					label: 'form.number',
				},
			},
		],
		preview: ({ data, t }) => data.vehicles_number,
		condition: (data) => data.has_vehicle === 'yes',
	},
	{
		depth: 1,
		route: 'has_asset_abroad',
		label: ({ t }) => t('successionOnboarding.steps.hasAssetAbroad.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasAssetAbroad.title'),
		subtitle: ({ t }) => t('successionOnboarding.steps.hasAssetAbroad.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'has_asset_abroad',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_asset_abroad === 'yes' ? t('global.yes')
			: data.has_asset_abroad === 'no' ? t('global.no')
				: data.has_asset_abroad === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		route: 'part_four',
		label: ({ t }) => t('successionOnboarding.steps.partFour.label'),
		title: ({ t }) => t('successionOnboarding.steps.partFour.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'successionOnboarding.steps.partFour.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
  {
		depth: 1,
		route: 'mutual_insurance',
		label: ({ t }) => t('successionOnboarding.steps.mutualInsurance.label'),
		title: ({ t }) => t('successionOnboarding.steps.mutualInsurance.title'),
		items: [
			{
				type: 'oneOf',
				name: 'mutual_insurance',
				props: {
					options: [
						{ value: "caami", label: "successionOnboarding.steps.mutualInsurance.form.caami" },
						{ value: "centrale_soins_services_domicile", label: "successionOnboarding.steps.mutualInsurance.form.centrale_soins_services_domicile" },
						{ value: "mutualite_neutre", label: "successionOnboarding.steps.mutualInsurance.form.mutualite_neutre" },
						{ value: "mutualite_socialiste_du_brabant", label: "successionOnboarding.steps.mutualInsurance.form.mutualite_socialiste_du_brabant" },
						{ value: "solidaris_wallonie", label: "successionOnboarding.steps.mutualInsurance.form.solidaris_wallonie" },
						{ value: "mutualia", label: "successionOnboarding.steps.mutualInsurance.form.mutualia" },
						{ value: "mutuelle_liberale", label: "successionOnboarding.steps.mutualInsurance.form.mutuelle_liberale" },
						{ value: "neutraal_ziekenfonds", label: "successionOnboarding.steps.mutualInsurance.form.neutraal_ziekenfonds" },
						{ value: "socialistische_mutualiteiten", label: "successionOnboarding.steps.mutualInsurance.form.socialistische_mutualiteiten" },
						{ value: "hr_railcare", label: "successionOnboarding.steps.mutualInsurance.form.hr_railcare" },
						{ value: "mutualite_chretienne", label: "successionOnboarding.steps.mutualInsurance.form.mutualite_chretienne" },
						{ value: "helan", label: "successionOnboarding.steps.mutualInsurance.form.helan" },
						{ value: "partenamut", label: "successionOnboarding.steps.mutualInsurance.form.partenamut" },
						{ value: "other", label: "successionOnboarding.steps.mutualInsurance.form.other" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.mutual_insurance === 'caami' ? t('successionOnboarding.steps.mutualInsurance.form.caami')
			: data.mutual_insurance === 'centrale_soins_services_domicile' ? t('successionOnboarding.steps.mutualInsurance.form.centrale_soins_services_domicile')
				: data.mutual_insurance === 'mutualite_neutre' ? t('successionOnboarding.steps.mutualInsurance.form.mutualite_neutre')
					: data.mutual_insurance === 'mutualite_socialiste_du_brabant' ? t('successionOnboarding.steps.mutualInsurance.form.mutualite_socialiste_du_brabant')
						: data.mutual_insurance === 'solidaris_wallonie' ? t('successionOnboarding.steps.mutualInsurance.form.solidaris_wallonie')
							: data.mutual_insurance === 'mutualia' ? t('successionOnboarding.steps.mutualInsurance.form.mutualia')
								: data.mutual_insurance === 'mutuelle_liberale' ? t('successionOnboarding.steps.mutualInsurance.form.mutuelle_liberale')
									: data.mutual_insurance === 'neutraal_ziekenfonds' ? t('successionOnboarding.steps.mutualInsurance.form.neutraal_ziekenfonds')
									  : data.mutual_insurance === 'socialistische_mutualiteiten' ? t('successionOnboarding.steps.mutualInsurance.form.socialistische_mutualiteiten')
									    : data.mutual_insurance === 'hr_railcare' ? t('successionOnboarding.steps.mutualInsurance.form.hr_railcare')
                        : data.mutual_insurance === 'mutualite_chretienne' ? t('successionOnboarding.steps.mutualInsurance.form.mutualite_chretienne')
                          : data.mutual_insurance === 'helan' ? t('successionOnboarding.steps.mutualInsurance.form.helan')
                            : data.mutual_insurance === 'partenamut' ? t('successionOnboarding.steps.mutualInsurance.form.partenamut')
                              : data.mutual_insurance === 'other' ? t('successionOnboarding.steps.mutualInsurance.form.other')
									            	: '',
	},
  {
		depth: 1,
		route: 'has_insurance_broker',
		label: ({ t }) => t('successionOnboarding.steps.hasInsuranceBroker.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasInsuranceBroker.title'),
		items: [
			{
				type: 'oneOf',
				name: 'has_insurance_broker',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_insurance_broker === 'yes' ? t('global.yes')
			: data.has_insurance_broker === 'no' ? t('global.no')
				: data.has_insurance_broker === 'unknown' ? t('form.unknown')
					: '',
	},
  {
		depth: 2,
		route: 'insurance_broker',
		label: ({ t }) => t('successionOnboarding.steps.insuranceBroker.label'),
		title: ({ t }) => t('successionOnboarding.steps.insuranceBroker.title'),
		items: [
			{
				type: 'field',
				name: 'insurance_broker_name',
				gridSizes: {
					lg: 6,
				},
				props: {
					type: 'text',
					label: 'form.insuranceBroker.name',
				},
			},
			{
				type: 'field',
				name: 'insurance_broker_email',
				props: {
					type: 'text',
					label: 'form.insuranceBroker.email',
				},
				gridSizes: {
					lg: 6,
				},
			},
		],
    preview: ({ data, t }) => ((data.insurance_broker_name || data.insurance_broker_email ) ) ? `${data.insurance_broker_name} : ${data.insurance_broker_email}` : '',
    condition: (data) => data.has_insurance_broker === 'yes',
  },
  {
    depth: 1,
    route: 'health_insurance_company',
    label: ({ t }) => t('successionOnboarding.steps.healthInsurance.label'),
    title: ({ t }) => t('successionOnboarding.steps.healthInsurance.title'),
    items: [
      {
        type: 'oneOf',
        name: 'health_insurance',
        props: {
          options: [
            { value: "dkv", label: "successionOnboarding.steps.insurance.form.dkv" },
            { value: "ethias", label: "successionOnboarding.steps.insurance.form.ethias" },
            { value: "axa", label: "successionOnboarding.steps.insurance.form.axa" },
            { value: "ag", label: "successionOnboarding.steps.insurance.form.ag" },
            { value: "van_breda", label: "successionOnboarding.steps.insurance.form.van_breda" },
            { value: "medexel", label: "successionOnboarding.steps.insurance.form.medexel" },
            { value: "nn_assurance_health", label: "successionOnboarding.steps.insurance.form.nn_assurance_health" },
            { value: "vivium", label: "successionOnboarding.steps.insurance.form.vivium" },
            { value: "allianz", label: "successionOnboarding.steps.insurance.form.allianz" },
            { value: "belfius_direct_hospi", label: "successionOnboarding.steps.insurance.form.belfius_direct_hospi" },
            { value: "other", label: "successionOnboarding.steps.insurance.form.other" },
            { value: "none", label: "successionOnboarding.steps.insurance.form.none" },
          ],
        },
      },
    ],
    preview: ({ data, t }) => data.health_insurance === 'dkv' ? t('successionOnboarding.steps.insurance.form.dkv')
      : data.health_insurance === 'ethias' ? t('successionOnboarding.steps.insurance.form.ethias')
        : data.health_insurance === 'axa' ? t('successionOnboarding.steps.insurance.form.axa')
          : data.health_insurance === 'ag' ? t('successionOnboarding.steps.insurance.form.ag')
            : data.health_insurance === 'van_breda' ? t('successionOnboarding.steps.insurance.form.van_breda')
              : data.health_insurance === 'medexel' ? t('successionOnboarding.steps.insurance.form.medexel')
                : data.health_insurance === 'nn_assurance_health' ? t('successionOnboarding.steps.insurance.form.nn_assurance_health')
                  : data.health_insurance === 'vivium' ? t('successionOnboarding.steps.insurance.form.vivium')
                    : data.health_insurance === 'allianz' ? t('successionOnboarding.steps.insurance.form.allianz')
                      : data.health_insurance === 'belfius_direct_hospi' ? t('successionOnboarding.steps.insurance.form.belfius_direct_hospi')
                        : data.health_insurance === 'other' ? t('successionOnboarding.steps.insurance.form.other')
                          : data.health_insurance === 'none' ? t('successionOnboarding.steps.insurance.form.none')
                            : '',
    condition: (data) => data.has_insurance_broker !== 'yes',
  },
  {
		depth: 2,
		route: 'health_insurance',
		label: ({ t }) => t('successionOnboarding.steps.healthInsuranceCompany.label'),
		title: ({ t }) => t('successionOnboarding.steps.healthInsuranceCompany.title'),
		items: [
			{
				type: 'field',
				name: 'health_insurance_number',
				props: {
					type: 'text',
					label: 'form.insuranceCompany.number',
				},
				gridSizes: {
					lg: 12,
				},
			},
		],
    preview: ({ data, t }) => ((data.health_insurance_number ) ) ? `${data.health_insurance_number}` : '',
    condition: (data) => data.health_insurance !== 'none' && (data.has_insurance_broker !== 'yes'),
  },
  {
    depth: 1,
    route: 'damage_insurance_company',
    label: ({ t }) => t('successionOnboarding.steps.damageInsurance.label'),
    title: ({ t }) => t('successionOnboarding.steps.damageInsurance.title'),
    items: [
      {
        type: 'oneOf',
        name: 'damage_insurance',
        props: {
          options: [
            { value: "acm_insurance", label: "successionOnboarding.steps.insurance.form.acm_insurance" },
            { value: "actel", label: "successionOnboarding.steps.insurance.form.actel" },
            { value: "ag", label: "successionOnboarding.steps.insurance.form.ag" },
            { value: "allianz", label: "successionOnboarding.steps.insurance.form.allianz" },
            { value: "argenta", label: "successionOnboarding.steps.insurance.form.argenta" },
            { value: "axa", label: "successionOnboarding.steps.insurance.form.axa" },
            { value: "baloise", label: "successionOnboarding.steps.insurance.form.baloise" },
            { value: "belfius", label: "successionOnboarding.steps.insurance.form.belfius" },
            { value: "belfius_direct_habitation", label: "successionOnboarding.steps.insurance.form.belfius_direct_habitation" },
            { value: "das", label: "successionOnboarding.steps.insurance.form.das" },
            { value: "dvv", label: "successionOnboarding.steps.insurance.form.dvv" },
            { value: "ethias", label: "successionOnboarding.steps.insurance.form.ethias" },
            { value: "europ_assistance", label: "successionOnboarding.steps.insurance.form.europ_assistance" },
            { value: "federale_assurance", label: "successionOnboarding.steps.insurance.form.federale_assurance" },
            { value: "kbc", label: "successionOnboarding.steps.insurance.form.kbc" },
            { value: "nn_assurance", label: "successionOnboarding.steps.insurance.form.nn_assurance" },
            { value: "none", label: "successionOnboarding.steps.insurance.form.none" },
            { value: "other", label: "successionOnboarding.steps.insurance.form.other" },
            { value: "pv", label: "successionOnboarding.steps.insurance.form.pv" },
            { value: "touring", label: "successionOnboarding.steps.insurance.form.touring" },
            { value: "vivium", label: "successionOnboarding.steps.insurance.form.vivium" },
            { value: "yuzzu", label: "successionOnboarding.steps.insurance.form.yuzzu" },
          ],
        },
      },
    ],
    preview: ({ data, t }) => data.damage_insurance === 'acm_insurance' ? t('successionOnboarding.steps.insurance.form.acm_insurance')
      : data.damage_insurance === 'ethias' ? t('successionOnboarding.steps.insurance.form.ethias')
        : data.damage_insurance === 'axa' ? t('successionOnboarding.steps.insurance.form.axa')
          : data.damage_insurance === 'ag' ? t('successionOnboarding.steps.insurance.form.ag')
            : data.damage_insurance === 'vivium' ? t('successionOnboarding.steps.insurance.form.vivium')
              : data.damage_insurance === 'allianz' ? t('successionOnboarding.steps.insurance.form.allianz')
                : data.damage_insurance === 'belfius' ? t('successionOnboarding.steps.insurance.form.belfius')
                  : data.damage_insurance === 'belfius_direct_habitation' ? t('successionOnboarding.steps.insurance.form.belfius_direct_habitation')
                    : data.damage_insurance === 'yuzzu' ? t('successionOnboarding.steps.insurance.form.yuzzu')
                      : data.damage_insurance === 'baloise' ? t('successionOnboarding.steps.insurance.form.baloise')
                        : data.damage_insurance === 'pv' ? t('successionOnboarding.steps.insurance.form.pv')
                          : data.damage_insurance === 'dvv' ? t('successionOnboarding.steps.insurance.form.dvv')
                            : data.damage_insurance === 'touring' ? t('successionOnboarding.steps.insurance.form.touring')
                              : data.damage_insurance === 'nn_assurance' ? t('successionOnboarding.steps.insurance.form.nn_assurance')
                                : data.damage_insurance === 'das' ? t('successionOnboarding.steps.insurance.form.das')
                                  : data.damage_insurance === 'europ_assistance' ? t('successionOnboarding.steps.insurance.form.europ_assistance')
                                    : data.damage_insurance === 'federale_assurance' ? t('successionOnboarding.steps.insurance.form.federale_assurance')
                                      : data.damage_insurance === 'argenta' ? t('successionOnboarding.steps.insurance.form.argenta')
                                        : data.damage_insurance === 'actel' ? t('successionOnboarding.steps.insurance.form.actel')
                                          : data.damage_insurance === 'kbc' ? t('successionOnboarding.steps.insurance.form.kbc')
                                            : data.damage_insurance === 'other' ? t('successionOnboarding.steps.insurance.form.other')
                                              : data.damage_insurance === 'none' ? t('successionOnboarding.steps.insurance.form.none')
                                                : '',
    condition: (data) => data.has_insurance_broker !== 'yes',
  },
  {
		depth: 2,
		route: 'damage_insurance',
		label: ({ t }) => t('successionOnboarding.steps.damageInsuranceCompany.label'),
		title: ({ t }) => t('successionOnboarding.steps.damageInsuranceCompany.title'),
		items: [
			{
				type: 'field',
				name: 'damage_insurance_number',
				props: {
					type: 'text',
					label: 'form.insuranceCompany.number',
				},
				gridSizes: {
					lg: 12,
				},
			},
		],
    preview: ({ data, t }) => ((data.damage_insurance_number ) ) ? `${data.damage_insurance_number}` : '',
    condition: (data) => data.damage_insurance !== 'none' && (data.has_insurance_broker !== 'yes'),
  },
  {
    depth: 1,
    route: 'car_insurance_company',
    label: ({ t }) => t('successionOnboarding.steps.carInsurance.label'),
    title: ({ t }) => t('successionOnboarding.steps.carInsurance.title'),
    items: [
      {
        type: 'oneOf',
        name: 'car_insurance',
        props: {
          options: [
            { value: "acm_insurance", label: "successionOnboarding.steps.insurance.form.acm_insurance" },
            { value: "actel", label: "successionOnboarding.steps.insurance.form.actel" },
            { value: "ag", label: "successionOnboarding.steps.insurance.form.ag" },
            { value: "allianz", label: "successionOnboarding.steps.insurance.form.allianz" },
            { value: "argenta", label: "successionOnboarding.steps.insurance.form.argenta" },
            { value: "axa", label: "successionOnboarding.steps.insurance.form.axa" },
            { value: "baloise", label: "successionOnboarding.steps.insurance.form.baloise" },
            { value: "belfius", label: "successionOnboarding.steps.insurance.form.belfius" },
            { value: "das", label: "successionOnboarding.steps.insurance.form.das" },
            { value: "dvv", label: "successionOnboarding.steps.insurance.form.dvv" },
            { value: "ethias", label: "successionOnboarding.steps.insurance.form.ethias" },
            { value: "europ_assistance", label: "successionOnboarding.steps.insurance.form.europ_assistance" },
            { value: "federale_assurance", label: "successionOnboarding.steps.insurance.form.federale_assurance" },
            { value: "kbc", label: "successionOnboarding.steps.insurance.form.kbc" },
            { value: "nn_assurance", label: "successionOnboarding.steps.insurance.form.nn_assurance" },
            { value: "none", label: "successionOnboarding.steps.insurance.form.none" },
            { value: "other", label: "successionOnboarding.steps.insurance.form.other" },
            { value: "pv", label: "successionOnboarding.steps.insurance.form.pv" },
            { value: "touring", label: "successionOnboarding.steps.insurance.form.touring" },
            { value: "vivium", label: "successionOnboarding.steps.insurance.form.vivium" },
            { value: "yuzzu", label: "successionOnboarding.steps.insurance.form.yuzzu" },
          ],
        },
      },
    ],
    preview: ({ data, t }) => data.car_insurance === 'acm_insurance' ? t('successionOnboarding.steps.insurance.form.acm_insurance')
      : data.car_insurance === 'ethias' ? t('successionOnboarding.steps.insurance.form.ethias')
        : data.car_insurance === 'axa' ? t('successionOnboarding.steps.insurance.form.axa')
          : data.car_insurance === 'ag' ? t('successionOnboarding.steps.insurance.form.ag')
            : data.car_insurance === 'vivium' ? t('successionOnboarding.steps.insurance.form.vivium')
              : data.car_insurance === 'allianz' ? t('successionOnboarding.steps.insurance.form.allianz')
                : data.car_insurance === 'belfius' ? t('successionOnboarding.steps.insurance.form.belfius')
                  : data.car_insurance === 'yuzzu' ? t('successionOnboarding.steps.insurance.form.yuzzu')
                    : data.car_insurance === 'baloise' ? t('successionOnboarding.steps.insurance.form.baloise')
                      : data.car_insurance === 'pv' ? t('successionOnboarding.steps.insurance.form.pv')
                        : data.car_insurance === 'dvv' ? t('successionOnboarding.steps.insurance.form.dvv')
                          : data.car_insurance === 'touring' ? t('successionOnboarding.steps.insurance.form.touring')
                            : data.car_insurance === 'nn_assurance' ? t('successionOnboarding.steps.insurance.form.nn_assurance')
                              : data.car_insurance === 'das' ? t('successionOnboarding.steps.insurance.form.das')
                                : data.car_insurance === 'europ_assistance' ? t('successionOnboarding.steps.insurance.form.europ_assistance')
                                  : data.car_insurance === 'federale_assurance' ? t('successionOnboarding.steps.insurance.form.federale_assurance')
                                    : data.car_insurance === 'argenta' ? t('successionOnboarding.steps.insurance.form.argenta')
                                      : data.car_insurance === 'actel' ? t('successionOnboarding.steps.insurance.form.actel')
                                        : data.car_insurance === 'kbc' ? t('successionOnboarding.steps.insurance.form.kbc')
                                          : data.car_insurance === 'other' ? t('successionOnboarding.steps.insurance.form.other')
                                            : data.car_insurance === 'none' ? t('successionOnboarding.steps.insurance.form.none')
                                              : '',
    condition: (data) => (data.has_insurance_broker !== 'yes') && data.has_vehicle === 'yes',
  },
  {
		depth: 2,
		route: 'car_insurance',
		label: ({ t }) => t('successionOnboarding.steps.carInsuranceCompany.label'),
		title: ({ t }) => t('successionOnboarding.steps.carInsuranceCompany.title'),
		items: [
			{
				type: 'field',
				name: 'car_insurance_number',
				props: {
					type: 'text',
					label: 'form.insuranceCompany.number',
				},
				gridSizes: {
					lg: 12,
				},
			},
		],
    preview: ({ data, t }) => ((data.car_insurance_number ) ) ? `${data.car_insurance_number}` : '',
    condition: (data) => data.car_insurance !== 'none' && (data.has_insurance_broker !== 'yes') && data.has_vehicle === 'yes',
  },
  {
    depth: 1,
    route: 'fire_insurance_company',
    label: ({ t }) => t('successionOnboarding.steps.fireInsurance.label'),
    title: ({ t }) => t('successionOnboarding.steps.fireInsurance.title'),
    items: [
      {
        type: 'oneOf',
        name: 'fire_insurance',
        props: {
          options: [
            { value: "acm_insurance", label: "successionOnboarding.steps.insurance.form.acm_insurance" },
            { value: "actel", label: "successionOnboarding.steps.insurance.form.actel" },
            { value: "ag", label: "successionOnboarding.steps.insurance.form.ag" },
            { value: "allianz", label: "successionOnboarding.steps.insurance.form.allianz" },
            { value: "argenta", label: "successionOnboarding.steps.insurance.form.argenta" },
            { value: "axa", label: "successionOnboarding.steps.insurance.form.axa" },
            { value: "baloise", label: "successionOnboarding.steps.insurance.form.baloise" },
            { value: "belfius", label: "successionOnboarding.steps.insurance.form.belfius" },
            { value: "belfius_direct_habitation", label: "successionOnboarding.steps.insurance.form.belfius_direct_habitation" },
            { value: "das", label: "successionOnboarding.steps.insurance.form.das" },
            { value: "dvv", label: "successionOnboarding.steps.insurance.form.dvv" },
            { value: "ethias", label: "successionOnboarding.steps.insurance.form.ethias" },
            { value: "europ_assistance", label: "successionOnboarding.steps.insurance.form.europ_assistance" },
            { value: "federale_assurance", label: "successionOnboarding.steps.insurance.form.federale_assurance" },
            { value: "kbc", label: "successionOnboarding.steps.insurance.form.kbc" },
            { value: "nn_assurance", label: "successionOnboarding.steps.insurance.form.nn_assurance" },
            { value: "none", label: "successionOnboarding.steps.insurance.form.none" },
            { value: "other", label: "successionOnboarding.steps.insurance.form.other" },
            { value: "pv", label: "successionOnboarding.steps.insurance.form.pv" },
            { value: "touring", label: "successionOnboarding.steps.insurance.form.touring" },
            { value: "vivium", label: "successionOnboarding.steps.insurance.form.vivium" },
            { value: "yuzzu", label: "successionOnboarding.steps.insurance.form.yuzzu" },
          ],
        },
      },
    ],
    preview: ({ data, t }) => data.fire_insurance === 'acm_insurance' ? t('successionOnboarding.steps.insurance.form.acm_insurance')
      : data.fire_insurance === 'ethias' ? t('successionOnboarding.steps.insurance.form.ethias')
        : data.fire_insurance === 'axa' ? t('successionOnboarding.steps.insurance.form.axa')
          : data.fire_insurance === 'ag' ? t('successionOnboarding.steps.insurance.form.ag')
            : data.fire_insurance === 'vivium' ? t('successionOnboarding.steps.insurance.form.vivium')
              : data.fire_insurance === 'allianz' ? t('successionOnboarding.steps.insurance.form.allianz')
                : data.fire_insurance === 'belfius' ? t('successionOnboarding.steps.insurance.form.belfius')
                  : data.fire_insurance === 'belfius_direct_habitation' ? t('successionOnboarding.steps.insurance.form.belfius_direct_habitation')
                    : data.fire_insurance === 'yuzzu' ? t('successionOnboarding.steps.insurance.form.yuzzu')
                      : data.fire_insurance === 'baloise' ? t('successionOnboarding.steps.insurance.form.baloise')
                        : data.fire_insurance === 'pv' ? t('successionOnboarding.steps.insurance.form.pv')
                          : data.fire_insurance === 'dvv' ? t('successionOnboarding.steps.insurance.form.dvv')
                            : data.fire_insurance === 'touring' ? t('successionOnboarding.steps.insurance.form.touring')
                              : data.fire_insurance === 'nn_assurance' ? t('successionOnboarding.steps.insurance.form.nn_assurance')
                                : data.fire_insurance === 'das' ? t('successionOnboarding.steps.insurance.form.das')
                                  : data.fire_insurance === 'europ_assistance' ? t('successionOnboarding.steps.insurance.form.europ_assistance')
                                    : data.fire_insurance === 'federale_assurance' ? t('successionOnboarding.steps.insurance.form.federale_assurance')
                                      : data.fire_insurance === 'argenta' ? t('successionOnboarding.steps.insurance.form.argenta')
                                        : data.fire_insurance === 'actel' ? t('successionOnboarding.steps.insurance.form.actel')
                                          : data.fire_insurance === 'kbc' ? t('successionOnboarding.steps.insurance.form.kbc')
                                            : data.fire_insurance === 'other' ? t('successionOnboarding.steps.insurance.form.other')
                                              : data.fire_insurance === 'none' ? t('successionOnboarding.steps.insurance.form.none')
                                                : '',
    condition: (data) => (data.has_insurance_broker !== 'yes') && data.has_property === 'yes',
  },
  {
		depth: 2,
		route: 'fire_insurance',
		label: ({ t }) => t('successionOnboarding.steps.fireInsuranceCompany.label'),
		title: ({ t }) => t('successionOnboarding.steps.fireInsuranceCompany.title'),
		items: [
			{
				type: 'field',
				name: 'fire_insurance_number',
				props: {
					type: 'text',
					label: 'form.insuranceCompany.number',
				},
				gridSizes: {
					lg: 12,
				},
			},
		],
    preview: ({ data, t }) => ((data.fire_insurance_number ) ) ? `${data.fire_insurance_number}` : '',
    condition: (data) => data.fire_insurance !== 'none' && (data.has_insurance_broker !== 'yes') && data.has_property === 'yes',
  },
  {
		depth: 1,
		route: 'other_insurance',
		label: ({ t }) => t('successionOnboarding.steps.hasOtherInsurance.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasOtherInsurance.title'),
		items: [
			{
				type: 'oneOf',
				name: 'has_other_insurance',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_other_insurance === 'yes' ? t('global.yes')
			: data.has_other_insurance === 'no' ? t('global.no')
				: data.has_other_insurance === 'unknown' ? t('form.unknown')
					: '',
    condition: (data) => data.has_insurance_broker !== 'yes'
	},
  {
		depth: 2,
		route: 'other_insurance_comment',
		label: ({ t }) => t('successionOnboarding.steps.otherInsurance.label'),
		title: ({ t }) => t('successionOnboarding.steps.otherInsurance.title'),
		items: [
			{
				type: 'field',
				name: 'other_insurance_comment',
				props: {
					type: 'textarea',
					label: 'form.otherInsurance.comment',
				},
				gridSizes: {
					lg: 12,
				},
			},
		],
    preview: ({ data, t }) => ((data.other_insurance_comment ) ) ? `${data.other_insurance_comment}` : '',
    condition: (data) => data.has_other_insurance === 'yes' && (data.has_insurance_broker !== 'no' || data.has_insurance_broker !== 'unknown')
  },
  {
		route: 'part_five',
		label: ({ t }) => t('successionOnboarding.steps.partFive.label'),
		title: ({ t }) => t('successionOnboarding.steps.partFive.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'successionOnboarding.steps.partFive.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
  {
		depth: 1,
		route: 'water_supplier',
		label: ({ t }) => t('successionOnboarding.steps.waterSupplier.label'),
		title: ({ t }) => t('successionOnboarding.steps.waterSupplier.title'),
		items: [
			{
				type: 'oneOf',
				name: 'water_supplier',
				props: {
					options: [
						{ value: "aeic", label: "successionOnboarding.steps.waterSupplier.form.aeic" },
						{ value: "cile", label: "successionOnboarding.steps.waterSupplier.form.cile" },
						{ value: "farys", label: "successionOnboarding.steps.waterSupplier.form.farys" },
						{ value: "ieg", label: "successionOnboarding.steps.waterSupplier.form.ieg" },
						{ value: "inasep", label: "successionOnboarding.steps.waterSupplier.form.inasep" },
						{ value: "inbw", label: "successionOnboarding.steps.waterSupplier.form.inbw" },
						{ value: "other", label: "successionOnboarding.steps.waterSupplier.form.other" },
						{ value: "pidpa", label: "successionOnboarding.steps.waterSupplier.form.pidpa" },
						{ value: "wallon_society", label: "successionOnboarding.steps.waterSupplier.form.wallon_society" },
						{ value: "vivaqua", label: "successionOnboarding.steps.waterSupplier.form.vivaqua" },
						{ value: "watergroep", label: "successionOnboarding.steps.waterSupplier.form.watergroep" },
						{ value: "waterlink", label: "successionOnboarding.steps.waterSupplier.form.waterlink" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.water_supplier === 'waterlink' ? t('successionOnboarding.steps.waterSupplier.form.waterlink')
			: data.water_supplier === 'wallon_society' ? t('successionOnboarding.steps.waterSupplier.form.wallon_society')
				: data.water_supplier === 'pidpa' ? t('successionOnboarding.steps.waterSupplier.form.pidpa')
					: data.water_supplier === 'farys' ? t('successionOnboarding.steps.waterSupplier.form.farys')
						: data.water_supplier === 'other' ? t('successionOnboarding.steps.waterSupplier.form.other')
							: data.water_supplier === 'inbw' ? t('successionOnboarding.steps.waterSupplier.form.inbw')
								: data.water_supplier === 'aeic' ? t('successionOnboarding.steps.waterSupplier.form.aeic')
									: data.water_supplier === 'ieg' ? t('successionOnboarding.steps.waterSupplier.form.ieg')
									  : data.water_supplier === 'inasep' ? t('successionOnboarding.steps.waterSupplier.form.inasep')
									    : data.water_supplier === 'vivaqua' ? t('successionOnboarding.steps.waterSupplier.form.vivaqua')
                        : data.water_supplier === 'watergroep' ? t('successionOnboarding.steps.waterSupplier.form.watergroep')
                          : data.water_supplier === 'cile' ? t('successionOnboarding.steps.waterSupplier.form.cile')
                            : '',
	},
  {
		depth: 1,
		route: 'water_supplier_data',
		label: ({ t }) => t('successionOnboarding.steps.waterSupplierData.label'),
		title: ({ t }) => t('successionOnboarding.steps.waterSupplierData.title'),
		items: [
			{
				type: 'field',
				name: 'water_supplier_client_number',
				props: {
					type: 'text',
					label: 'form.supplier.number',
				},
				gridSizes: {
					lg: 6,
				},
			},
      {
        type: 'field',
        name: 'water_supplier_meter_reading',
        props: {
          type: 'text',
          label: 'form.supplier.meter_reading',
        },
        gridSizes: {
          lg: 6,
        },
      },
      {
				type: 'field',
				name: 'water_supplier_reading_date',
				props: {
					type: 'multipart_date',
					label: 'form.supplier.reading_date',
				},
			},
      {
				type: 'field',
				name: 'water_supplier_meter_number',
				props: {
					type: 'text',
					label: 'form.supplier.meter_number',
				},
				gridSizes: {
					lg: 5,
				},
			},
		],
    preview: ({ data, t }) => (data.water_supplier_client_number) ? `${data.water_supplier_client_number}` : '',
  },
  {
		depth: 1,
		route: 'gas_supplier',
		label: ({ t }) => t('successionOnboarding.steps.gasSupplier.label'),
		title: ({ t }) => t('successionOnboarding.steps.gasSupplier.title'),
		items: [
			{
				type: 'oneOf',
				name: 'gas_supplier',
				props: {
					options: [
						{ value: "primagaz", label: "successionOnboarding.steps.gasSupplier.form.primagaz" },
						{ value: "luminus", label: "successionOnboarding.steps.gasSupplier.form.luminus" },
						{ value: "proxifuel", label: "successionOnboarding.steps.gasSupplier.form.proxifuel" },
						{ value: "ores", label: "successionOnboarding.steps.gasSupplier.form.ores" },
						{ value: "bulex", label: "successionOnboarding.steps.gasSupplier.form.bulex" },
						{ value: "octa", label: "successionOnboarding.steps.gasSupplier.form.octa" },
						{ value: "other", label: "successionOnboarding.steps.gasSupplier.form.other" },
						{ value: "total", label: "successionOnboarding.steps.gasSupplier.form.total" },
						{ value: "eneco", label: "successionOnboarding.steps.gasSupplier.form.eneco" },
						{ value: "mega", label: "successionOnboarding.steps.gasSupplier.form.mega" },
						{ value: "engie_electrabel", label: "successionOnboarding.steps.gasSupplier.form.engie_electrabel" },
						{ value: "elegant", label: "successionOnboarding.steps.gasSupplier.form.elegant" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.gas_supplier === 'elegant' ? t('successionOnboarding.steps.gasSupplier.form.elegant')
			: data.gas_supplier === 'eneco' ? t('successionOnboarding.steps.gasSupplier.form.eneco')
				: data.gas_supplier === 'total' ? t('successionOnboarding.steps.gasSupplier.form.total')
					: data.gas_supplier === 'proxifuel' ? t('successionOnboarding.steps.gasSupplier.form.proxifuel')
						: data.gas_supplier === 'other' ? t('successionOnboarding.steps.gasSupplier.form.other')
							: data.gas_supplier === 'octa' ? t('successionOnboarding.steps.gasSupplier.form.octa')
								: data.gas_supplier === 'primagaz' ? t('successionOnboarding.steps.gasSupplier.form.primagaz')
									: data.gas_supplier === 'ores' ? t('successionOnboarding.steps.gasSupplier.form.ores')
									  : data.gas_supplier === 'bulex' ? t('successionOnboarding.steps.gasSupplier.form.bulex')
									    : data.gas_supplier === 'mega' ? t('successionOnboarding.steps.gasSupplier.form.mega')
                        : data.gas_supplier === 'engie_electrabel' ? t('successionOnboarding.steps.gasSupplier.form.engie_electrabel')
                          : data.gas_supplier === 'luminus' ? t('successionOnboarding.steps.gasSupplier.form.luminus')
                            : '',
	},
  {
		depth: 1,
		route: 'gas_supplier_data',
		label: ({ t }) => t('successionOnboarding.steps.gasSupplierData.label'),
		title: ({ t }) => t('successionOnboarding.steps.gasSupplierData.title'),
		items: [
			{
				type: 'field',
				name: 'gas_supplier_client_number',
				props: {
					type: 'text',
					label: 'form.supplier.number',
				},
				gridSizes: {
					lg: 6,
				},
			},
      {
        type: 'field',
        name: 'gas_supplier_meter_reading',
        props: {
          type: 'text',
          label: 'form.supplier.meter_reading',
        },
        gridSizes: {
          lg: 6,
        },
      },
      {
				type: 'field',
				name: 'gas_supplier_reading_date',
				props: {
					type: 'multipart_date',
					label: 'form.supplier.reading_date',
				},
			},
      {
				type: 'field',
				name: 'gas_supplier_meter_number',
				props: {
					type: 'text',
					label: 'form.supplier.meter_number',
				},
				gridSizes: {
					lg: 5,
				},
			},
		],
    preview: ({ data, t }) => (data.gas_supplier_client_number) ? `${data.gas_supplier_client_number}` : '',
  },
  {
		depth: 1,
		route: 'electricity_supplier',
		label: ({ t }) => t('successionOnboarding.steps.electricitySupplier.label'),
		title: ({ t }) => t('successionOnboarding.steps.electricitySupplier.title'),
		items: [
			{
				type: 'oneOf',
				name: 'electricity_supplier',
				props: {
					options: [
						{ value: "elegant", label: "successionOnboarding.steps.electricitySupplier.form.elegant" },
						{ value: "eneco", label: "successionOnboarding.steps.electricitySupplier.form.eneco" },
						{ value: "engie_electrabel", label: "successionOnboarding.steps.electricitySupplier.form.engie_electrabel" },
						{ value: "luminus", label: "successionOnboarding.steps.electricitySupplier.form.luminus" },
						{ value: "mega", label: "successionOnboarding.steps.electricitySupplier.form.mega" },
						{ value: "octa", label: "successionOnboarding.steps.electricitySupplier.form.octa" },
						{ value: "ores", label: "successionOnboarding.steps.electricitySupplier.form.ores" },
						{ value: "other", label: "successionOnboarding.steps.electricitySupplier.form.other" },
						{ value: "total", label: "successionOnboarding.steps.electricitySupplier.form.total" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.electricity_supplier === 'elegant' ? t('successionOnboarding.steps.electricitySupplier.form.elegant')
			: data.electricity_supplier === 'eneco' ? t('successionOnboarding.steps.electricitySupplier.form.eneco')
				: data.electricity_supplier === 'total' ? t('successionOnboarding.steps.electricitySupplier.form.total')
          : data.electricity_supplier === 'other' ? t('successionOnboarding.steps.electricitySupplier.form.other')
            : data.electricity_supplier === 'octa' ? t('successionOnboarding.steps.electricitySupplier.form.octa')
              : data.electricity_supplier === 'ores' ? t('successionOnboarding.steps.electricitySupplier.form.ores')
                : data.electricity_supplier === 'mega' ? t('successionOnboarding.steps.electricitySupplier.form.mega')
                  : data.electricity_supplier === 'engie_electrabel' ? t('successionOnboarding.steps.electricitySupplier.form.engie_electrabel')
                    : data.electricity_supplier === 'luminus' ? t('successionOnboarding.steps.electricitySupplier.form.luminus')
                      : '',
	},
  {
		depth: 1,
		route: 'electricity_supplier_data',
		label: ({ t }) => t('successionOnboarding.steps.electricitySupplierData.label'),
		title: ({ t }) => t('successionOnboarding.steps.electricitySupplierData.title'),
		items: [
			{
				type: 'field',
				name: 'electricity_supplier_client_number',
				props: {
					type: 'text',
					label: 'form.supplier.number',
				},
				gridSizes: {
					lg: 6,
				},
			},
      {
        type: 'field',
        name: 'electricity_supplier_meter_reading',
        props: {
          type: 'text',
          label: 'form.supplier.meter_reading',
        },
        gridSizes: {
          lg: 6,
        },
      },
      {
				type: 'field',
				name: 'electricity_supplier_reading_date',
				props: {
					type: 'multipart_date',
					label: 'form.supplier.reading_date',
				},
			},
			{
				type: 'field',
				name: 'electricity_supplier_meter_number',
				props: {
					type: 'text',
					label: 'form.supplier.meter_number',
				},
				gridSizes: {
					lg: 5,
				},
			},
		],
    preview: ({ data, t }) => (data.electricity_supplier_client_number) ? `${data.electricity_supplier_client_number}` : '',
  },
  {
    route: 'part_six',
		label: ({ t }) => t('successionOnboarding.steps.partSix.label'),
		title: ({ t }) => t('successionOnboarding.steps.partSix.title'),
		items: [
      {
        type: 'content',
				props: {
          content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'successionOnboarding.steps.partSix.content'}
							t={t}
							useMarkdown={true}
              />
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
              >{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
  {
    depth: 1,
    route: 'numerics_supplier',
    label: ({ t }) => t('successionOnboarding.steps.numericsSupplier.label'),
    title: ({ t }) => t('successionOnboarding.steps.numericsSupplier.title'),
    items: [
      {
        type: 'oneOf',
        name: 'numerics_supplier',
        props: {
          options: [
            { value: "voo", label: "successionOnboarding.steps.numericsSupplier.form.voo" },
            { value: "orange", label: "successionOnboarding.steps.numericsSupplier.form.orange" },
            { value: "scarlet", label: "successionOnboarding.steps.numericsSupplier.form.scarlet" },
            { value: "base", label: "successionOnboarding.steps.numericsSupplier.form.base" },
            { value: "betv", label: "successionOnboarding.steps.numericsSupplier.form.betv" },
            { value: "telenet", label: "successionOnboarding.steps.numericsSupplier.form.telenet" },
            { value: "other_numerics", label: "successionOnboarding.steps.numericsSupplier.form.other_numerics" },
            { value: "proximus", label: "successionOnboarding.steps.numericsSupplier.form.proximus" },
          ],
        },
      },
    ],
    preview: ({ data, t }) => data.numerics_supplier === 'voo' ? t('successionOnboarding.steps.numericsSupplier.form.voo')
      : data.numerics_supplier === 'proximus' ? t('successionOnboarding.steps.numericsSupplier.form.proximus')
        : data.numerics_supplier === 'other_numerics' ? t('successionOnboarding.steps.numericsSupplier.form.other_numerics')
          : data.numerics_supplier === 'betv' ? t('successionOnboarding.steps.numericsSupplier.form.betv')
            : data.numerics_supplier === 'telenet' ? t('successionOnboarding.steps.numericsSupplier.form.telenet')
              : data.numerics_supplier === 'base' ? t('successionOnboarding.steps.numericsSupplier.form.base')
                : data.numerics_supplier === 'orange' ? t('successionOnboarding.steps.numericsSupplier.form.orange')
                  : data.numerics_supplier === 'scarlet' ? t('successionOnboarding.steps.numericsSupplier.form.scarlet')
                    : '',
  },
 {
    depth: 1,
    route: 'numerics_supplier_data',
    label: ({ t }) => t('successionOnboarding.steps.numericsSupplierData.label'),
    title: ({ t }) => t('successionOnboarding.steps.numericsSupplierData.title'),
    items: [
      {
        type: 'field',
        name: 'numerics_supplier_client_number',
        props: {
          type: 'text',
          label: 'form.supplier.number',
        },
        gridSizes: {
          lg: 12,
        },
      },
      {
        type: 'field',
        name: 'numerics_supplier_mobile_number',
        props: {
          type: 'text',
          label: 'form.supplier.mobile_number',
        },
        gridSizes: {
          lg: 6,
        },
      },
      {
        type: 'field',
        name: 'numerics_supplier_landline_number',
        props: {
          type: 'text',
          label: 'form.supplier.landline_number',
        },
        gridSizes: {
          lg: 6,
        },
      }
    ],
    preview: ({ data, t }) => (data.numerics_supplier_client_number) ? `${data.numerics_supplier_client_number}` : '',
  },
  {
		depth: 1,
		route: 'other_contracts_data',
		label: ({ t }) => t('successionOnboarding.steps.otherContractsData.label'),
		title: ({ t }) => t('successionOnboarding.steps.otherContractsData.title'),
		items: [
			{
				type: 'field',
				name: 'other_contracts_comment',
				props: {
					type: 'textarea',
					label: 'form.otherContracts.comment',
				},
				gridSizes: {
					lg: 12,
				},
			},
		],
    preview: ({ data, t }) => (data.other_contracts_comment) ? `${data.other_contracts_comment}` : '',
  },
  {
		depth: 1,
		route: 'other_comment_data',
		label: ({ t }) => t('successionOnboarding.steps.otherCommentData.label'),
		title: ({ t }) => t('successionOnboarding.steps.otherCommentData.title'),
		items: [
			{
				type: 'field',
				name: 'other_comment',
				props: {
					type: 'textarea',
					label: 'successionOnboarding.steps.other.form.label',
				},
				gridSizes: {
					lg: 12,
				},
			},
		],
    preview: ({ data, t }) => (data.other_comment) ? `${data.other_comment}` : '',
  },
	{
		route: 'referral',
		label: ({ t }) => t('successionOnboarding.steps.referral.label'),
		title: ({ t }) => t('successionOnboarding.steps.referral.title'),
		items: [
			{
				type: 'oneOf',
				name: 'referral',
				props: {
					options: [
						{ value: "google", label: "successionOnboarding.steps.referral.form.google" },
						{ value: "facebook", label: "successionOnboarding.steps.referral.form.facebook" },
						{ value: "undertaker", label: "successionOnboarding.steps.referral.form.undertaker" },
						{ value: "press", label: "successionOnboarding.steps.referral.form.press" },
						{ value: "legacio_testament", label: "successionOnboarding.steps.referral.form.legacio_testament" },
						{ value: "relative", label: "successionOnboarding.steps.referral.form.relative" },
						{ value: "undertaker_website", label: "successionOnboarding.steps.referral.form.undertaker_website" },
						{ value: "legacio_succession", label: "successionOnboarding.steps.referral.form.legacio_succession" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.referral === 'google' ? t('successionOnboarding.steps.referral.form.google')
			: data.referral === 'facebook' ? t('successionOnboarding.steps.referral.form.facebook')
				: data.referral === 'undertaker' ? t('successionOnboarding.steps.referral.form.undertaker')
					: data.referral === 'press' ? t('successionOnboarding.steps.referral.form.press')
						: data.referral === 'legacio_testament' ? t('successionOnboarding.steps.referral.form.legacio_testament')
							: data.referral === 'relative' ? t('successionOnboarding.steps.referral.form.relative')
								: data.referral === 'undertaker_website' ? t('successionOnboarding.steps.referral.form.undertaker_website')
									: data.referral === 'legacio_succession' ? t('successionOnboarding.steps.referral.form.legacio_succession')
										: '',
	},
	{
		route: 'undertaker',
		label: ({ t }) => t('successionOnboarding.steps.undertaker.label'),
		title: ({ t }) => t('successionOnboarding.steps.undertaker.title'),
		items: [
			{
				type: 'field',
				name: 'undertaker',
				props: {
					label: 'successionOnboarding.steps.undertaker.form.label',
				},
			},
		],
		preview: ({ data, t }) => data.undertaker,
		condition: (data) => ['undertaker', 'undertaker_website'].includes(data.referral),
	},
	{
		route: 'other',
		label: ({ t }) => t('successionOnboarding.steps.other.label'),
		title: ({ t }) => t('successionOnboarding.steps.other.title'),
		items: [
			{
				type: 'field',
				name: 'other',
				props: {
					type: 'textarea',
					label: 'successionOnboarding.steps.other.form.label',
				},
			},
		],
		preview: ({ data, t }) => data.other ? t('successionOnboarding.steps.other.preview') : '',
	},
];

export function SuccessionOnboarding() {
	const { t } = useTranslation();

	const {
		data,
		setData,
		errors,
		getErrors,
		updateErrors,
		getFieldValues,
	} = useFormValues({
		defaultData,
		dataSchema,
	});
	const {
		handleChange,
	} = useFormUpdaters({
		setData,
	});

	const postMutation = useMutation((data) => {
		return postSuccessionOnboarding(data);
	});

	async function sendData() {
		const response = await postMutation.mutateAsync(data);

		if (!!response) localStorage.removeItem('succession_onboarding');

		return response;
	}

	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		/* 1. Token */
		let token = '';

		const tokenEncoded = searchParams.get('token') || '';
		if (!!tokenEncoded) {
			try {
				token = window.atob(tokenEncoded.replace(/_/g, '/').replace(/-/g, '+'));
			} catch {
				// In case of an error when copy pasting the link for example, avoid crashing
			}
		}

		if (!token) {
			// No token from url (can happen after a reload or if the user comes back) or incorrectly encoded
			// Look inside the SuccessionOnboarding data in the `localStorage`
			const storedItem = localStorage.getItem('succession_onboarding');
			token = storedItem ? (JSON.parse(storedItem)?.token || '') : '';
		}

		if (!!token) {
			localStorage.setItem('succession_onboarding', JSON.stringify({ token: token, }));
			setSearchParams({});

			return;
		}

		/* 2. Full name (fallback) */
		let fullName = '';

		const fullNameEncoded = searchParams.get('full_name') || '';
		if (!!fullNameEncoded) {
			try {
				fullName = window.atob(fullNameEncoded.replace(/_/g, '/').replace(/-/g, '+'));
			} catch {
				// In case of an error when copy pasting the link for example, avoid crashing
			}
		}

		if (!fullName) {
			// No name from url (can happen after a reload or if the user comes back) or incorrectly encoded
			// Look inside the SuccessionOnboarding data in the `localStorage`
			const storedItem = localStorage.getItem('succession_onboarding');
			fullName = storedItem ? (JSON.parse(storedItem)?.full_name || '') : '';
		}

		if (!!fullName) {
			localStorage.setItem('succession_onboarding', JSON.stringify({ full_name: fullName, }));
			setSearchParams({});
			handleChange(['full_name', fullName]);

			return;
		}

		/* 3. None, should not be there! */
		navigate('/succession-onboarding-invalid-url', { replace: true, });
	}, []);

	const isMedium = useMediaQuery(mq('md'));

	return <>
		<ContainerWithSidebar
			sidebarFitDesktopScreen={true}
			showSidebarOnMobile={true}
			sidebar={
				<>
					<Tutorial
						id='stepper_menu'
						placement={isMedium ? 'right-start' : 'bottom'}
						description={t('tutorials.stepperMenu')}
					>
						<div>
							<Typo
								component='p'
								variant='title-2'
								css={{ marginBottom: spacing(2), }}
							>{t('stepper.menu.title')}</Typo>
						</div>
					</Tutorial>
					<ScrollDisplay
						component={CardBox}
						wrapperCss={{
							padding: 0,
						}}
						css={{
							padding: spacing(4),
						}}
					>
						<StepperMenu
							steps={steps}
							data={data}
							errors={errors}
						/>
					</ScrollDisplay>
					{/* <NeedHelp
						css={{ marginTop: spacing(4), }}
					/> */}
				</>
			}
		>
			{/* <Breadcrumb
						css={{
							marginBottom: spacing(2),
						}}
					/> */}
			{/* <Typo
						component='h1'
						variant='marketing-title'
						css={{
							marginBottom: spacing(7),
							[mq('lg')]: {
								marginBottom: spacing(12),
							},
						}}
					>Ma déclaration de dernières volontés quant aux obsèques</Typo> */}
			<div
				css={{
					paddingBottom: spacing(12),
				}}
			>
				<Stepper
					steps={steps}
					data={data}
					setData={setData}
					dataSchema={dataSchema}
					errors={errors}
					updateFormErrors={updateErrors}
					getFormErrors={getErrors}
					getFieldValues={getFieldValues}
					allowInvalid={false}
					validEndRedirect={'/succession-onboarding-success'}
					endCallback={sendData}
				/>
			</div>
		</ContainerWithSidebar>
		<Container>
			<div
				css={{
					marginBottom: spacing(12),
					borderBottom: '1px solid',
					borderColor: palette.gray[300],
				}}
			/>
			{/* <section
			css={{
				// marginBottom: spacing(12),
			}}
		>
			<Support />
		</section> */}
		</Container>
	</>
}
